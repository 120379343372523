<template>
  <OnBoarding>
    <template>
      <CRow>
        <CCol>
         <OnBoardingHeader :title="$gettext('Create account')"/>

          <CAlert v-if="isErrorVisible" show color="danger">{{ errorMessage }}</CAlert>
          <CInput
              ref="email"
              placeholder="E-mail"
              v-model="email"
              required
              :label="$gettext('E-mail')"
              autocomplete="off"/>

          <InputPassword
              v-model="password"
              :required="true"
              autocomplete="new-password"
              :id="'password'"
              :label="$gettext('Password')"
              :placeholder="'Password'"/>

          <InputPassword
              v-model="confirmPassword"
              :required="true"
              :id="'confirmPassword'"
              autocomplete="new-password"
              :placeholder="'Confirm password'"/>

          <CButton type="button" color="primary" block v-translate @click="signup">Create account
          </CButton>

          <div class="text-center info">
            <span v-translate>otherwise</span>
          </div>
          <SocialLogin allow-register/>
          <AlreadyHaveAccount/>
        </CCol>
      </CRow>
    </template>
  </OnBoarding>
</template>

<script>
import InputPassword from "@/components/InputPassword.vue";
import SocialLogin from "@/domain/login/components/SocialLogin.vue";
import {mapActions} from "vuex";
import {useVuelidate} from "@vuelidate/core";
import {required, email, minLength, sameAs} from "@vuelidate/validators";
import {methods, computed} from "@/domain/login/mixin";
import OnBoarding from "@/domain/login/OnBoarding.vue";
import AlreadyHaveAccount from "@/domain/login/components/AlreadyHaveAccount.vue";
import OnBoardingHeader from "@/domain/login/components/OnBoardingHeader.vue";

export default {
  name: 'Register',
  components: {OnBoardingHeader, AlreadyHaveAccount, OnBoarding, InputPassword, SocialLogin},

  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      submitted: false,
      error: null
    }
  },
  mounted() {
    if (this.invitation)
      this.email = this.invitation.email

    this.$refs.email.$el.children[0].focus()
    this.checkQueryParams()
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      email: {required, email},
      password: {required, minLength: minLength(8)},
      confirmPassword: {required, sameAsPassword: sameAs(this.password)},
    }
  },
  computed: {
    ...computed,
    isEmailValid() {
      if (!this.email || this.v$.email.$invalid)
        return false
      return true
    },
    isPasswordValid() {
      if (!this.password || this.v$.password.$invalid)
        return false
      return true
    },
    isConfirmPasswordValid() {
      if (!this.confirmPassword || this.v$.confirmPassword.$invalid)
        return false
      return true
    },
    isValid() {
      return !this.v$.$invalid
    },
    isErrorVisible() {
      if ((this.submitted && !this.isValid) || this.error)
        return true
      return false
    },
    errorMessage() {
      if (!this.isEmailValid)
        return 'Invalid E-mail'
      if (!this.isPasswordValid)
        return 'Password must be min 8 characters'
      if (!this.isConfirmPasswordValid)
        return 'Password and Confirm Password should match'
      if (this.error)
        return this.error
      return null
    }
  },
  methods: {
    ...methods,
    ...mapActions('login', [
      'createUser',
      'authenticate',
      'getCurrentUser',
    ]),

    async signup() {
      this.submitted = true
      this.error = null
      if (this.isValid) {
        try {
          await this.createUser({
            email: this.email,
            password: this.password,
          })
          await this.authenticate({
            email: this.email,
            password: this.password,
            remember: true
          })
          await this.afterLogin()
        } catch (e) {
          if (await this.manageLoginError(e, this.email)) return
          this.error = 'Register error'
        }
      }
    },
  }
}
</script>
