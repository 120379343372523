<template>
  <div class="input-show-hide-password">
    <CInput :type="type" :value="value" ref="inputPasswordRef"
            @input="$emit('input', $event)"
            v-on:keyup.enter="$emit('keyup-enter', $event)"
            :id="id"
            autofocus
            :autocomplete="autocomplete"
            :required="required"
            :invalid-feedback="invalidFeedback"
            :class="[extraClasses]"
            :label="label"
            :placeholder="placeholder"/>

    <a href="#" class="btn-eye" @click.prevent="toggleInputType" tabindex="-1">
      <CIcon :name="type === 'text' ? 'cip-eye': 'cip-eye-off' "/>
    </a>

  </div>

</template>

<script>
export default {
  name: "InputPassword",
  props: {
    value: {type: String, required: false},
    required: {type: Boolean, required: false},
    id: {type: String, required: false},
    extraClasses: {type: String, required: false},
    placeholder: {type: String, required: false},
    autocomplete: {type: String, required: false},
    isValid: {type: Boolean, required: false},
    invalidFeedback: {type: String, required: false},
    label: {type: String, required: false}
  },
  data() {
    return {
      type: 'password'
    }
  },
  methods: {
    toggleInputType() {
      this.type === 'password' ? this.type = 'text' : this.type = 'password'

      document.getElementById(this.id).focus()

    }
  }
}
</script>
